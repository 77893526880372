import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as AiIcons from 'react-icons/ai';

import { ActionTd, Table, TableContainer, TableWrapper, Td, Th, Tr } from '../TabelaReservas/styles';

import Button from '../../components/Button';
import Context, { IContext } from '../../context/Context';

import api from '../../services/api';
import CustomAlert from '../CustomAlert';
import { ITipEsp } from '../../types/TipEsp';
import { destratarData, tratarData } from '../../utils/tratarData';
import ReactModal from 'react-modal';
import useWindowDimensions from '../../utils/WindowDimensions';
import { IReserva } from '../../types/Reserva';
import { formatCurrency } from '../../utils/formatCurrency';
import { IConAdi } from '../../types/ConAdi';
import { IConvidado } from '../../types/Convidado';
import { ICheckout } from '../../types/Checkout';

export interface ICampos {
  campo: string;
  label: string;
  type?: 'text' | 'number' | 'date' | 'datetime-local' | 'email' | 'month' | 'range' | 'search' | 'tel' | 'time' | 'url' | 'week' | 'select' | 'checkbox' | 'radio';
  default?: any;
  options?: { value: string | number; label: string }[];
}

export interface IFormatoCampos {
  [key: string]: (value: any) => ReactNode;
}

interface ICadastroGenerico {
  entidade: string;
  endpoint: string;
  campos: ICampos[];
  formatoCampos?: IFormatoCampos;
  reserva?: IReserva;
  convidados?: IConvidado[]
}

interface IRegistro {
  [key: string]: any;
  editando?: boolean;
  cod?: number; // Adicionado para identificação de registros
}

function CadastroGenerico({ entidade, endpoint, campos, formatoCampos = {}, reserva, convidados }: ICadastroGenerico) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;
  const { setIsLoadingOverlay, usuario }: IContext = useContext(Context);
  const [data, setData] = useState<IRegistro[]>([]);
  const [tiposDeEspaco, setTiposDeEspaco] = useState<{ value: number; label: string }[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [convidadosAdicionaisPagos, setConvidadosAdicionaisPagos] = useState<IConAdi[]>([]);
  const [quantidadeConvidadosAdicionaisInput, setQuantidadeConvidadosAdicionaisInput] = useState<number>(0);
  const [quantidadeConvidadosAdicionaisPagos, setQuantidadeConvidadosAdicionaisPagos] = useState<number>(0);


  async function getData() {
    try {
      if (endpoint === '/convidados' && !reserva?.cod) return;
      setIsLoadingOverlay(true);

      let params = {};

      if (endpoint === '/datasEspeciais') {
        params = { datini: tratarData(new Date().toISOString()) };
      }


      if (endpoint === '/convidados' && reserva?.cod) {
        params = { codres: reserva.cod };
      }


      const response = await api.get(endpoint, { params });

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao buscar ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function getTiposDeEspaco() {
    try {
      const response = await api.get('/tiposDeEspaco');
      if (response.status === 200) {
        const options = response.data.map((tipo: ITipEsp) => ({ value: tipo.cod, label: tipo.tipesp }));
        setTiposDeEspaco(options);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Tipos de Espaço');
    }
  }

  async function getConvidadosAdicionais() {
    try {
      if (endpoint === '/convidados' && !reserva?.cod) return;

      const response = await api.get('/convidadosAdicionais', {
        params: {
          codres: reserva!.cod
        }
      });

      if (response.status === 200) {
        const convidadosAdicionaisPagos = response.data.filter((convidadoAdicional: IConAdi) => convidadoAdicional.sta === 'PAGO');
        const totalQtdConvidadosAdicionaisPagos = convidadosAdicionaisPagos.reduce((total: number, convidadoAdicional: IConAdi) => total + convidadoAdicional.quacon, 0);

        setConvidadosAdicionaisPagos(convidadosAdicionaisPagos);
        setQuantidadeConvidadosAdicionaisPagos(totalQtdConvidadosAdicionaisPagos);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Convidados Adicionais');
    }
  }

  async function postData(novoRegistro: IRegistro) {
    try {
      setIsLoadingOverlay(true);

      const response = await api.post(endpoint, novoRegistro, {
        headers: {
          codusu: usuario?.cod
        }
      });

      if (response.status === 201) {
        toast.success(`${entidade} criado com sucesso`);
        getData();
      }
    } catch (error: any) {
      if (error.response?.data) {
        if (error.response.data.erro.includes('Limite de convidados atingido.')) return setModalVisible(true);

        return toast.error(error.response.data.erro);
      }

      toast.error(`Erro ao criar ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function postConAdi() {
    try {
      if (quantidadeConvidadosAdicionaisInput === 0) return toast.warning('Informe a quantidade de convidados adicionais');

      if (!reserva) return toast.warning('Falha ao buscar reserva');;

      setIsLoadingOverlay(true);
      const payload: IConAdi = {
        codres: reserva.cod,
        quacon: quantidadeConvidadosAdicionaisInput
      }

      if (usuario?.tip === 'ADMINISTRADOR' || usuario?.tip === 'ASSISTENTE') {
        payload.sta = 'PAGO';
      }

      const response = await api.post('/convidadosAdicionais', payload);

      if (response.status === 201) {

        if (usuario?.tip === 'ADMINISTRADOR' || usuario?.tip === 'ASSISTENTE') {
          toast.success(`Liberado ${quantidadeConvidadosAdicionaisInput} convidados adicionais`);
          getConvidadosAdicionais();
          setModalVisible(false);
        }

        const payloadCheckout: ICheckout = {
          cod: response.data.cod,
          cliente: {
            raz: usuario?.usu || 'Associado',
            tipo: 'PF',
            cod: usuario?.cod.toString() || '',
            cgc: usuario?.cgc || '',
            tipocgc: 'CPF'
          },
          produtos: [
            {
              valor: reserva.espaco.tipesp.valconadi,
              produto: `Convidado adicional - ${reserva.espaco.tipesp.tipesp}`,
              cod: response.data.cod,
              qua: quantidadeConvidadosAdicionaisInput
            }
          ],
          pagamentos: [
            {
              checkout: {
                expiracao: 15,
                editavel: true,
                metodospagamento: ['credit_card', 'debit_card', 'pix'],
                urlredirecionamento: window.location.origin + '/usuario/minhasReservas',
                pix: {
                  expiracao: 900
                }
              },
              metodopagamento: 'checkout'
            }
          ]
        }

        const responseCheckout = await api.post('/checkout', payloadCheckout);

        if (responseCheckout.status === 200) {
          window.location.href = responseCheckout.data.checkoutUrl;
        }

      }

    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao Concluir Compra de Convidado Adicional`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function putData(registro: IRegistro) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.put(`${endpoint}/${registro.cod}`, registro);
      if (response.status === 200) {
        toast.success(`${entidade} atualizado com sucesso`);
        getData();
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao atualizar ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function deleteData(id: number) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.delete(`${endpoint}/${id}`);
      if (response.status === 204) {
        toast.success(`${entidade} excluído com sucesso`);
        getData();
      }
    } catch (error: any) {
      if (error.response?.data) return toast.error(error.response.data.erro);
      toast.error(`Erro ao excluir ${entidade}`);
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  function handleButtonClick() {
    if (entidade === 'Convidado' && (reserva?.usuario.cod === Number(usuario?.cod)) && data.filter(item => !item.editando).length >= (reserva!.espaco.tipesp.limcon + quantidadeConvidadosAdicionaisPagos)) {
      if (reserva!.espaco.tipesp.limconadi === 0) {
        return toast.warning('Não permitido');
      }

      return setModalVisible(true);
    }

    const novoRegistro = data.find(item => item.editando);

    if (!novoRegistro) {
      const novoItem = campos.reduce((acc, campo) => ({
        ...acc,
        [campo.campo]: campo.default || '',
        editando: true
      }), {});
      setData([...data, novoItem]);
      return;
    }

    if (reserva?.cod) {
      novoRegistro['codres'] = reserva.cod;

      if (novoRegistro?.con.split(' ').length < 2) return toast.warning('Obrigatório informar Nome Completo do Convidado');
      if (entidade === 'Convidado' && (reserva?.usuario.cod !== Number(usuario?.cod)) && !novoRegistro.obs) return toast.warning('Administradores devem informar observação do convidado');
    }
    if (endpoint === '/espacos' || endpoint === '/datasEspeciais') {
      novoRegistro['codtipesp'] = novoRegistro?.tipesp?.cod;
    }

    delete novoRegistro['dathorins'];

    if (novoRegistro.cod) {
      putData(novoRegistro); // Atualiza o registro se um código existir
    } else {
      postData(novoRegistro); // Cria um novo registro se não houver código
    }
  }

  const renderCampo = (campo: ICampos, item: IRegistro, index: number) => {
    if (campo.type === 'select' && tiposDeEspaco.length > 0) {
      return tiposDeEspaco.find(option => option.value === item?.tipesp?.cod)?.label;
    }

    return formatoCampos[campo.campo] ? formatoCampos[campo.campo](item[campo.campo]) : item[campo.campo];
  };

  function Modal() {
    return (
      <ReactModal
        isOpen={modalVisible}
        appElement={document.getElementById('root') as HTMLElement}
        contentLabel='Minimal Modal Example'
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalVisible(false)}
        style={{
          overlay: {
            backgroundColor: '#1D1D1D',
            opacity: 0.9,
            zIndex: 99
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            height: '30%',
            width: isMobile ? '80%' : '30%',
            margin: 'auto',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          },
        }}
      >
        <b>Aumentar Limite de Convidados Por {formatCurrency(reserva!.espaco.tipesp.valconadi!)} cada. Limite: {reserva!.espaco.tipesp.limconadi - quantidadeConvidadosAdicionaisPagos} </b>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <label>Convidados Adicionais: </label>
          <input
            type='number'
            onChange={(e) => {
              if (Number(e.target.value) > (reserva!.espaco.tipesp.limconadi - quantidadeConvidadosAdicionaisPagos)) return setQuantidadeConvidadosAdicionaisInput(reserva!.espaco.tipesp.limconadi - quantidadeConvidadosAdicionaisPagos);

              setQuantidadeConvidadosAdicionaisInput(Number(e.target.value))
            }}
            value={quantidadeConvidadosAdicionaisInput}
            style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '4px', marginLeft: '10px' }}
          />
        </div>
        Total: {formatCurrency(quantidadeConvidadosAdicionaisInput * (reserva!.espaco.tipesp.valconadi))}
        <Button texto='Confirmar Pedido' onClick={() => postConAdi()} style={{ width: isMobile ? '80%' : '40%' }} />
      </ReactModal>
    )
  }

  useEffect(() => {
    if (entidade === 'Convidado' && convidados) {
      console.log('entrou');
      setData(convidados)
      getConvidadosAdicionais();
      return;
    }

    if (entidade === 'Espaço' || entidade === 'Datas Especiais') {
      getTiposDeEspaco();
    }

    getData();
  }, []);

  return (
    <TableContainer>
      {(entidade === 'Convidado' && reserva) && <Modal />}
      <h1>{entidade === 'Convidado' ? 'Lista de Convidados' : `Cadastro de ${entidade}`}</h1>
      <br />
      <TableWrapper>
        <Table>
          <thead>
            <Tr>
              {campos.map(campo => <Th key={campo.campo} fontSize={entidade === 'Tipo de Espaço' ? '12px' : ''}>{campo.label}</Th>)}
              <Th style={{ width: '100px' }}>Ações</Th>
            </Tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              !item.editando ? (
                <Tr key={index}>
                  {campos.map(campo => (
                    <Td key={campo.campo}>
                      {renderCampo(campo, item, index)}
                    </Td>
                  ))}
                  <ActionTd>
                    <AiIcons.AiFillEdit onClick={() => setData(data.map((data, i) => i === index ? { ...data, editando: true } : { ...data, editando: false }))} />
                    <AiIcons.AiFillDelete
                      onClick={() => CustomAlert({
                        title: `Exclusão de ${entidade}`,
                        message: `Deseja prosseguir com a exclusão?`,
                        onConfirm: async () => {
                          await deleteData(item.cod!);
                        },
                        onCancel: () => { }
                      })}
                    />
                  </ActionTd>
                </Tr>
              ) : (
                <Tr key={index} $editando>
                  {campos.map(campo => (
                    <Td key={campo.campo}>
                      {campo.type !== 'select' ? (
                        <input
                          type={campo.type || 'text'}
                          defaultValue={campo.type === 'date' ? destratarData(item[campo.campo]) : item[campo.campo] === 0 ? '0' : item[campo.campo] || ''}
                          onChange={(e) => {
                            setData(data.map((d, i) => i === index ? { ...d, [campo.campo]: e.target.value.replace(',', '.') } : d));
                          }}
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <select
                          defaultValue={item.tipesp?.cod.toString() || ''}
                          onChange={(e) => {
                            setData(data.map((data, i) => i === index ? { ...data, tipesp: { cod: +e.target.value } } : data));
                          }}
                        >
                          <option value='' disabled>Selecione</option>
                          {tiposDeEspaco.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      )}
                    </Td>
                  ))}
                  <ActionTd>
                    <AiIcons.AiFillCheckCircle onClick={() => handleButtonClick()} />
                    <AiIcons.AiFillCloseCircle
                      onClick={() => {
                        if (item.cod) {
                          setData(data.map((data, i) => i === index ? { ...data, editando: false } : data));
                          return;
                        }
                        setData(data.filter((data, i) => i !== index))
                      }}
                    />
                  </ActionTd>
                </Tr>
              )
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      {entidade === 'Convidado' ?
        <>
          {reserva && <p style={{ textAlign: 'right' }}>Total de Convidados: {data.length}/{reserva!.espaco.tipesp.limcon + quantidadeConvidadosAdicionaisPagos}</p>}
          {/* {convidadosAdicionaisPagos.length > 0 &&
          <p style={{ textAlign: 'right' }}>Adicionais: {quantidadeConvidadosAdicionaisPagos}</p>} */}
        </>
        :
        <p style={{ textAlign: 'right' }}>Total de Registros: {data.length}</p>
      }
      <Button
        texto={!data.some(item => item.editando) ? 'Novo' : 'Salvar'}
        onClick={handleButtonClick}
        style={{ width: '100%', margin: '10px 0px' }}
      />
      {entidade === 'Convidado' && !(reserva!.espaco.tipesp.limconadi === 0 && (reserva?.usuario.cod === Number(usuario?.cod))) && (
        <Button
          texto={'Aumentar Limite de Convidados'}
          onClick={() => setModalVisible(true)}
          style={{ width: '100%', margin: '10px 0px', backgroundColor: 'green' }}
        />
      )}
    </TableContainer>
  );
}

export default CadastroGenerico;
