import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Container, SelectWrapper, Select, Label, Option, SelectContainer, InputsContainer } from './styles';

import api from '../../services/api';
import { ITipEsp } from '../../types/TipEsp';
import { IEspaco } from '../../types/Espaco';
import Button from '../Button';
import AgendaReserva from '../AgendaReserva';
import { useNavigate } from 'react-router-dom';
import Context, { IContext } from '../../context/Context';
import { cpfMask } from '../../utils/validaCpfCnpj';
import FormInput from '../FormInput';
import { IUsuario } from '../../types/Usuario';


interface IFormData {
  cgc: string;
  obs: string;
}

export default function NovaReserva({ slide }: { slide: string }) {
  const navigate = useNavigate();
  const { usuario, setIsLoadingOverlay }: IContext = useContext(Context);

  const [tiposDeEspaco, setTiposDeEspaco] = useState<ITipEsp[]>([]);
  const [tipoDeEspacoSelecionado, setTipoDeEspacoSelecionado] = useState<ITipEsp | undefined>();
  const [espacos, setEspacos] = useState<IEspaco[]>([]);
  const [espacoSelecionado, setEspacoSelecionado] = useState<IEspaco | undefined>();
  const [indexCarousel, setIndexCarousel] = useState<number>(0);
  const [formData, setFormData] = useState<IFormData>({
    cgc: '',
    obs: '',
  });
  const [usuarioReserva, setUsuarioReserva] = useState<IUsuario | undefined>();

  async function getTiposDeEspaco() {
    try {
      const response = await api.get('/tiposDeEspaco');

      if (response.status === 200) {
        setTiposDeEspaco(response.data);
      }
    } catch (error: any) {
      toast.error('Erro ao buscar Tipos de Espaço');
    }
  }

  async function getEspacos(codTipEsp: number) {
    try {
      setEspacoSelecionado(undefined);

      const response = await api.get('/espacos', {
        params: {
          codtipesp: codTipEsp
        }
      });

      if (response.status === 200) {
        setEspacos(response.data);
      }
    } catch (error) {
      toast.error('Erro ao buscar Espaços');
    }
  }

  async function getUsuario(cgc: string) {
    try {
      setIsLoadingOverlay(true);
      const response = await api.get('/usuarios', {
        params: {
          cgc
        }
      });

      if (response.status === 200 && response.data.length > 0) {
        setUsuarioReserva(response.data[0]);
        return;
      }

      setUsuarioReserva(undefined);
      toast.warning('Usuário não encontrado. Verifique o Cpf e tente novamente.');
    } catch (error) {
      toast.error('Falha ao buscar usuário por cpf');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 200);
    }
  }

  useEffect(() => {
    getTiposDeEspaco();
  }, []);

  useEffect(() => {
    if (slide === 'agenda') return setIndexCarousel(1);

    setIndexCarousel(0);
  }, [slide]);

  useEffect(() => {
    if (tipoDeEspacoSelecionado?.cod) {
      getEspacos(tipoDeEspacoSelecionado.cod);
    }
  }, [tipoDeEspacoSelecionado]);

  return (
    <Container>
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable={false}
        emulateTouch={false}
        infiniteLoop={false}
        showIndicators={false}
        autoPlay={false}
        selectedItem={indexCarousel}
        onChange={setIndexCarousel}
        className='carousel'
      >
        <SelectContainer>
          <h1>Nova Reserva</h1>
          <SelectWrapper>
            <Label>Escolha o Tipo de Espaço para Reserva:</Label>
            <Select
              onChange={e => setTipoDeEspacoSelecionado(tiposDeEspaco.find(tipoDeEspaco => tipoDeEspaco.cod === +e.target.value))}
              value={tipoDeEspacoSelecionado?.cod || ''}
            >
              <Option value='' disabled>Selecione o Tipo de Espaço</Option>
              {tiposDeEspaco.map((tipoDeEspaco: ITipEsp, index) => (
                <Option key={index} value={tipoDeEspaco.cod}>{tipoDeEspaco.tipesp}</Option>
              ))}
            </Select>
          </SelectWrapper>

          {tipoDeEspacoSelecionado && (
            <SelectWrapper>
              <Label>Escolha o Espaço para a Reserva:</Label>
              <Select
                onChange={e => setEspacoSelecionado(espacos.find(espaco => espaco.cod === +e.target.value))}
                value={espacoSelecionado?.cod || ''}
              >
                <Option value='' disabled>Selecione o Espaço</Option>
                {espacos.map((espaco: IEspaco, index) => (
                  <Option key={index} value={espaco.cod}>{espaco.esp}</Option>
                ))}
              </Select>
            </SelectWrapper>
          )}

          {((usuario?.tip === 'ADMINISTRADOR' || usuario?.tip === 'ASSISTENTE') && espacoSelecionado) &&
            <InputsContainer>
              <Label>Reservar para Outro Usuário: {usuarioReserva?.usu || ''}</Label>
              <div style={{ width: '100%' }}>
                <FormInput
                  placeHolder='CPF do Titular'
                  type='tel'
                  required
                  maxLength={14}
                  onChange={e => setFormData({ ...formData, cgc: cpfMask(e.target.value) })}
                  value={formData.cgc}
                  style={{ marginLeft: -15 }}
                  onBlur={() => {
                    if (formData.cgc && formData.cgc.length === 14) {
                      getUsuario(formData.cgc);
                      return;
                    }

                    setFormData({ ...formData, cgc: '' });
                    setUsuarioReserva(undefined);
                  }}
                />
                <FormInput
                  placeHolder='Observação'
                  type='text'
                  required
                  onChange={e => setFormData({ ...formData, obs: e.target.value })}
                  value={formData.obs}
                  style={{ marginLeft: -15 }}
                />
              </div>
            </InputsContainer>
          }

          {(espacoSelecionado && (!formData.cgc || (formData.cgc && formData.cgc.length === 14 && usuarioReserva?.cod))) &&
            <Button
              texto='Consultar Disponibilidade'
              onClick={async () => {
                navigate('/usuario/cadastro/novaReserva/agenda');
                setIndexCarousel(1);
              }}
              style={{ width: '90%', marginTop: '20px' }}
              animated
            />}
        </SelectContainer>
        <>{(espacoSelecionado && indexCarousel === 1) &&
          <AgendaReserva espaco={espacoSelecionado} detalhesReserva={usuarioReserva ? {codusu: usuarioReserva.cod, usu: usuarioReserva.usu, obs: formData.obs} : undefined} />
        }</>
      </Carousel>
    </Container>
  );
}
