import React, { useContext, useEffect, Fragment, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isFuture,
  isPast,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';

import { IReserva } from '../../types/Reserva';
import api from '../../services/api';
import Context, { IContext } from '../../context/Context';
import CustomAlert from '../CustomAlert';
import { IEspaco } from '../../types/Espaco';
import { formatCurrency } from '../../utils/formatCurrency';
import { IDatEsp } from '../../types/DatEsp';
import { tratarData } from '../../utils/tratarData';
import { ICheckout } from '../../types/Checkout';
import { useNavigate } from 'react-router-dom';

interface IAgendaReservaProps {
  espaco: IEspaco;
  detalhesReserva?: {
    codusu: number;
    obs?: string;
    usu?: string;
  }
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export default function AgendaReserva({ espaco, detalhesReserva }: IAgendaReservaProps) {
  const navigate = useNavigate();
  const { usuario, setIsLoadingOverlay }: IContext = useContext(Context);

  const hoje = startOfToday();

  const [diaSelecionado, setDiaSelecionado] = useState(hoje);
  const [mesAtual, setMesAtual] = useState(format(hoje, 'MMM-yyyy'));
  const [reservas, setReservas] = useState<IReserva[]>([]);
  const [datasEspeciais, setDatasEspeciais] = useState<IDatEsp[]>([]);
  // const [reservaEditando, setReservaEditando] = useState<IReserva | undefined>();

  const dataLimiteParaReserva = new Date(new Date().setDate(new Date().getDate() + (espaco.tipesp.prares === 0 ? 999 : espaco.tipesp.prares)));

  const reservasFiltradas = reservas.filter(reserva => reserva.espaco.cod === espaco.cod);

  const primeiroDiaDoMesAtual = parse(mesAtual, 'MMM-yyyy', new Date());

  const dias = eachDayOfInterval({
    start: primeiroDiaDoMesAtual,
    end: endOfMonth(primeiroDiaDoMesAtual),
  })

  function mesAnterior() {
    const primeiroDiaDoMesAnterior = add(primeiroDiaDoMesAtual, { months: -1 });
    setMesAtual(format(primeiroDiaDoMesAnterior, 'MMM-yyyy'));
  }

  function proximoMes() {
    const primeiroDiaDoProximoMes = add(primeiroDiaDoMesAtual, { months: 1 })
    setMesAtual(format(primeiroDiaDoProximoMes, 'MMM-yyyy'))
  }

  const reservasDoDiaSelecionado = reservas.filter((reserva) =>
    isSameDay(parseISO(formataData(reserva.datres)), diaSelecionado)
  )

  function formataData(data: string) {
    const parsedDate = parse(data, 'dd/MM/yyyy', new Date());
    const dataFormatada = format(parsedDate, "yyyy-MM-dd'T'HH:mm");

    return dataFormatada;
  }

  async function getData() {
    try {
      setIsLoadingOverlay(true);

      // Disparar ambas as chamadas de API simultaneamente
      const [reservasResponse, datasEspeciaisResponse] = await Promise.all([
        api.get('/reservas', {
          params: {
            codesp: espaco.cod,
            sta: 'PAGO'
          }
        }),
        api.get('/datasEspeciais', {
          params: {
            codtipesp: espaco.tipesp.cod
          }
        })
      ]);

      // Processar as respostas se todas forem bem-sucedidas
      if (reservasResponse.status === 200) {
        setReservas(reservasResponse.data);
      }

      if (datasEspeciaisResponse.status === 200) {
        setDatasEspeciais(datasEspeciaisResponse.data);
      }
    } catch (error: any) {
      if (error.response?.data?.erro) {
        toast.error(error.response.data.erro);
      } else {
        toast.error('Erro ao buscar dados');
      }
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function postCheckout(datRes: Date) {
    try {
      setIsLoadingOverlay(true);

      //confirmar se espaço já foi reservado
      const response = await api.get('/reservas', {
        params: {
          codesp: espaco.cod,
          datres: datRes.toISOString().substring(0, 10),
          sta: 'PAGO'
        }
      });

      if (response.data.length > 0) {
        toast.error('Está data não está mais disponível para reserva');
        getData();
        return
      }

      if (!usuario?.cod) return toast.error('Erro ao criar reserva, faça login novamente.');

      const payloadReserva = {
        codesp: espaco.cod,
        datres: datRes.toISOString().substring(0, 10),
        codusu: detalhesReserva?.codusu ? detalhesReserva.codusu : usuario.cod,
        obs: detalhesReserva?.obs ? detalhesReserva.obs : '',
        ...(detalhesReserva?.codusu && { sta: 'PAGO' })
      }

      const responseReserva = await api.post('/reservas', payloadReserva);

      if (!responseReserva.data?.cod) return toast.error('Erro ao criar reserva');

      if (detalhesReserva?.codusu) {
        toast.success('Reserva concluída');
        navigate('/usuario/agenda');
        return;
      }

      const payloadCheckout: ICheckout = {
        cod: responseReserva.data.cod,
        cliente: {
          raz: usuario?.usu || 'Associado',
          tipo: 'PF',
          cod: usuario?.cod.toString() || '',
          cgc: usuario?.cgc || '',
          tipocgc: 'CPF'
        },
        produtos: [
          {
            valor: valoresReserva(datRes),
            produto: 'Reserva ' + espaco.esp + ' ' + format(datRes, 'dd/MM/yyyy', { locale: ptBR }),
            cod: espaco.cod.toString(),
            qua: 1
          }
        ],
        pagamentos: [
          {
            checkout: {
              expiracao: 15,
              editavel: true,
              metodospagamento: ['credit_card', 'debit_card', 'pix'],
              urlredirecionamento: window.location.origin + '/usuario/minhasReservas',
              pix: {
                expiracao: 900
              }
            },
            metodopagamento: 'checkout'
          }
        ]
      }

      const responseCheckout = await api.post('/checkout', payloadCheckout);

      if (responseCheckout.status === 200) {
        window.location.href = responseCheckout.data.checkoutUrl;
      }
    } catch (error: any) {
      if (error.response?.data?.erro) return toast.error(error.response.data.erro);
      toast.error('Erro ao concluir reserva');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  function valoresReserva(dia: Date): number {
    const diaDaSemana = getDay(dia);

    const dataEspecial = datasEspeciais.find((dataEspecial) => tratarData(dia.toISOString()) === dataEspecial.dat);
    if (dataEspecial) return dataEspecial.val;

    switch (diaDaSemana) {
      case 0:
        return espaco.tipesp.valdom;
      case 1:
        return espaco.tipesp.valseg;
      case 2:
        return espaco.tipesp.valter;
      case 3:
        return espaco.tipesp.valqua;
      case 4:
        return espaco.tipesp.valqui;
      case 5:
        return espaco.tipesp.valsex;
      case 6:
        return espaco.tipesp.valsab;
      default:
        return 0;
    }
  }

  async function handleDiaButtonClick(dia: Date) {
    if (isPast(dia) && !isToday(dia)) return;

    setDiaSelecionado(dia);
    CustomAlert({
      title: `Confirmação de Reserva ${espaco.esp}`,
      message: detalhesReserva?.codusu ?
        `Deseja Prosseguir com a Reserva Para o Dia ${format(dia, 'dd/MM/yyyy', { locale: ptBR })} para o Associado ${detalhesReserva.usu}?` :
        `Deseja Prosseguir com a Reserva Para o Dia ${format(dia, 'dd/MM/yyyy', { locale: ptBR })} no Valor de ${formatCurrency(valoresReserva(dia))}?`,
      onConfirm: async () => await postCheckout(dia),
      onCancel: () => { }
    })
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='w-full'>
      <h1 className='text-center text-3xl font-extrabold sm:text-4xl mb-10 mt-5'>Agenda - {espaco.esp}</h1>
      <div className='max-w-md px-4 mx-auto sm:px-7 md:max-w-4xl md:px-6'>
        {/* <div className='md:grid md:grid-cols-2 md:divide-x md:divide-gray-200'> */}
        <div className='w-full h-full'>
          <div className='md:pr-0'>
            <div className='flex items-center b'>
              <h2 className='flex-auto font-semibold text-gray-900'>
                {format(primeiroDiaDoMesAtual, 'MMMM yyyy', { locale: ptBR })}
              </h2>
              <button
                type='button'
                onClick={mesAnterior}
                className='-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
              >
                <span className='sr-only'>Previous month</span>
                <AiIcons.AiOutlineLeft className='w-5 h-5' aria-hidden='true' />
              </button>
              <button
                onClick={proximoMes}
                type='button'
                className='-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
              >
                <span className='sr-only'>Next month</span>
                <AiIcons.AiOutlineRight className='w-5 h-5' aria-hidden='true' />
              </button>
            </div>
            <div className='grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500'>
              <div>D</div>
              <div>S</div>
              <div>T</div>
              <div>Q</div>
              <div>Q</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className='grid grid-cols-7 mt-1 text-sm'>
              {dias.map((dia, index) => (
                <div
                  key={dia.toString()}
                  className={classNames(
                    index === 0 && colStartClasses[getDay(dia)],
                    'py-1.5'
                  )}
                >
                  <button
                    type='button'
                    onClick={() => handleDiaButtonClick(dia)}
                    disabled={reservasFiltradas.some(reserva =>
                      isSameDay(parseISO(formataData(reserva.datres)), dia)) ||
                      datasEspeciais.find((dataEspecial) => tratarData(dia.toISOString()) === dataEspecial.dat)?.val === 0 ||
                      valoresReserva(dia) === 0 ||
                      dia >= dataLimiteParaReserva
                    }
                    className={classNames(
                      isEqual(dia, diaSelecionado) && 'text-white',
                      !isEqual(dia, diaSelecionado) &&
                      isToday(dia) &&
                      'font-semibold',
                      ((isPast(dia) && !isToday(dia)) || dia >= dataLimiteParaReserva) && 'text-red-500 cursor-not-allowed',
                      isFuture(dia) && 'font-semibold',
                      !isEqual(dia, diaSelecionado) &&
                      !isToday(dia) &&
                      isSameMonth(dia, primeiroDiaDoMesAtual) &&
                      'text-gray-900',
                      !isEqual(dia, diaSelecionado) &&
                      !isToday(dia) &&
                      !isSameMonth(dia, primeiroDiaDoMesAtual) &&
                      'text-gray-400',
                      isEqual(dia, diaSelecionado) && isToday(dia) && 'bg-black',
                      isEqual(dia, diaSelecionado) &&
                      !isToday(dia) &&
                      'bg-gray-900',
                      !isEqual(dia, diaSelecionado) && 'hover:bg-gray-200',
                      (isEqual(dia, diaSelecionado)) &&
                      'font-semibold',
                      `mx-auto flex h-8 w-8 items-center justify-center rounded-full
                      ${(reservasFiltradas.some(reserva => isSameDay(parseISO(formataData(reserva.datres)), dia)) &&
                        (!isPast(dia))) ||
                        datasEspeciais.find((dataEspecial) => tratarData(dia.toISOString()) === dataEspecial.dat)?.val === 0 ||
                        (valoresReserva(dia) === 0 && !isPast(dia))
                        ?
                        'bg-red-200 text-white cursor-not-allowed' : ''}`
                    )}
                  >
                    <time dateTime={format(dia, 'yyyy-MM-dd')}>
                      {format(dia, 'dd')}
                    </time>
                  </button>

                  <div className='mt-0.5'>
                    {(!isPast(dia) || isToday(dia)) && !reservas.some((reserva) =>
                      isSameDay(parseISO(formataData(reserva.datres)), dia)
                    ) && dia <= dataLimiteParaReserva ? (
                      <div className='flex items-center justify-center'>
                        <span className='text-black-500 text-[10px] text-center md:text-xs'>
                          {valoresReserva(dia) !== 0 ? formatCurrency(valoresReserva(dia)) : '-'}
                        </span>
                      </div>
                    ) :
                      (
                        <div className='flex items-center justify-center'>
                          <span className='text-red-500 text-[10px] text-center md:text-xs'>
                            -
                          </span>
                        </div>
                      )
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <section className='mt-12 md:mt-0 md:pl-14'>
            <h2 className='font-semibold text-gray-900'>
              Reservas do Dia{' '}
              <time dateTime={format(diaSelecionado, 'yyyy-MM-dd')}>
                {format(diaSelecionado, 'dd MMMM yyy', { locale: ptBR })}
              </time>
            </h2>
            <ol className='mt-4 space-y-1 text-sm leading-6 text-gray-500'>
              {reservasDoDiaSelecionado.length > 0 ? (
                reservasDoDiaSelecionado.map((reserva) => (
                  <Reserva
                    reserva={reserva}
                    key={reserva.cod}
                    setReservaEditando={setReservaEditando}
                    deletarReserva={deletarReserva}
                  />
                ))
              ) : (
                <p>Sem Reservas Para o Dia Selecionado.</p>
              )}
            </ol>
          </section> */}
        </div>
      </div>
    </div>
  )
}

// function Reserva(
//   {
//     reserva,
//     setReservaEditando,
//     deletarReserva
//   }:
//     {
//       reserva: IReserva,
//       setReservaEditando: React.Dispatch<React.SetStateAction<IReserva | undefined>>,
//       deletarReserva: (id: number) => void
//     }) {
//   return (
//     <li className='flex items-center px-4 py-2 space-x-4 group rounded-xl focus-within:bg-gray-100 hover:bg-gray-100'>
//       <img
//         src={
//           reserva.usuario.usu?.includes('Tiburcio') ?
//             'https://media.licdn.com/dms/image/D4D03AQFAp04XL06M6g/profile-displayphoto-shrink_800_800/0/1688586805712?e=1727913600&v=beta&t=utlIDoOe2MOMNCZLEvLIM9xY_GdRXDUzaS4c0zyI_LM' :
//             'https://png.pngtree.com/png-vector/20220608/ourlarge/pngtree-unknown-user-profile-person-faceless-png-image_4816462.png'
//         }
//         alt=''
//         className='flex-none w-10 h-10 rounded-full'
//       />
//       <div className='flex-auto'>
//         <p className='text-gray-900 font-semibold'>{reserva.espaco.esp}</p>
//         <p className='mt-0.5'>
//           {reserva.usuario?.usu}
//         </p>
//       </div>
//       <Menu
//         as='div'
//         className='relative opacity-0 focus-within:opacity-100 group-hover:opacity-100'
//       >
//         <div>
//           <MenuButton className='-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600'>
//             <span className='sr-only'>Open options</span>
//             <HiIcons.HiDotsVertical className='w-6 h-6' aria-hidden='true' />
//           </MenuButton>
//         </div>

//         <Transition
//           as={Fragment}
//           enter='transition ease-out duration-100'
//           enterFrom='transform opacity-0 scale-95'
//           enterTo='transform opacity-100 scale-100'
//           leave='transition ease-in duration-75'
//           leaveFrom='transform opacity-100 scale-100'
//           leaveTo='transform opacity-0 scale-95'
//         >
//           <MenuItems className='absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none'>
//             <div className='py-1'>
//               <MenuItems>
//                 {({ active }: any) => (
//                   <MenuItem v-slot="{ close }">
//                     <div
//                       onClick={() => setReservaEditando(reserva)}
//                       className={classNames(
//                         active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
//                         'block px-4 py-2 text-sm hover:bg-gray-100'
//                       )}
//                     >
//                       Alterar Data
//                     </div>
//                   </MenuItem>
//                 )}
//               </MenuItems>
//               <MenuItems>
//                 {({ active }: any) => (
//                   <MenuItem>
//                     <div
//                       onClick={() => CustomAlert({
//                         title: 'Cancelamento de Reserva',
//                         message: 'Deseja prosseguir com o cancelamento da reserva?',
//                         onConfirm: () => deletarReserva(reserva.cod),
//                         onCancel: () => { },
//                       })}
//                       className={classNames(
//                         active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 cursor-pointer',
//                         'block px-4 py-2 text-sm hover:bg-gray-100'
//                       )}
//                     >
//                       Cancelar Reserva
//                     </div>
//                   </MenuItem>
//                 )}
//               </MenuItems>
//             </div>
//           </MenuItems>
//         </Transition>
//       </Menu>
//     </li>
//   )
// }

let colStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]
