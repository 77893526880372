import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Container } from './styles';

import CadastroGenerico, { ICampos, IFormatoCampos } from '../../components/CadastroGenerico';
import { Table, TableContainer, TableWrapper, Td, Th, Tr } from '../../components/TabelaReservas/styles';

import Context, { IContext } from '../../context/Context';
import api from '../../services/api';
import { cpfMask } from '../../utils/validaCpfCnpj';
import { IReserva } from '../../types/Reserva';
import { IConvidado } from '../../types/Convidado';

export default function ReservaDetalhes() {
  const { cod } = useParams();

  const { setIsLoadingOverlay, usuario }: IContext = useContext(Context);

  const [reserva, setReserva] = useState<IReserva>();
  const [convidados, setConvidados] = useState<IConvidado[]>([]);

  async function getData() {
    try {
      if (!cod) return;

      setIsLoadingOverlay(true);

      const [reservasResponse, convidadosResponse] = await Promise.all([
        api.get(`/reservas/${cod}`),
        api.get('/convidados', {
          params: {
            codres: cod
          }
        })
      ]);

      // Processar as respostas se todas forem bem-sucedidas
      if (reservasResponse.status === 200) {
        setReserva(reservasResponse.data);
      }

      if (convidadosResponse.status === 200) {
        setConvidados(convidadosResponse.data);
      }

    } catch (error: any) {
      if (error.response?.data?.erro) {
        toast.error(error.response.data.erro);
      } else {
        toast.error('Erro ao buscar dados');
      }
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function verificarConvidado(cod: number, che: boolean) {
    try {
      setIsLoadingOverlay(true);

      const response = await api.patch(`/convidados/${cod}`, {
        che: che
      });

      if (response.status === 200) {
        convidados.forEach(convidado => {
          if (convidado.cod === cod) {
            convidado.che = che;
          }
        })
      }
    } catch (error) {
      toast.error('Erro ao verificar convidado');
    } finally {
      setIsLoadingOverlay(false);
    }
  }

  function getCadastroProps(entidade: string, endpoint: string, campos: ICampos[], formatoCampos?: IFormatoCampos) {
    return {
      entidade,
      endpoint,
      campos,
      formatoCampos
    }
  }

  const campos = [
    {
      campo: 'con',
      label: 'Nome Completo',
      type: 'text'
    },
    {
      campo: 'obs',
      label: 'Observação',
      type: 'text'
    }
  ] as ICampos[];

  if (import.meta.env.VITE_CODEMP !== '1') {
    campos.push({ campo: 'cgc', label: 'CPF/CNPJ', type: 'text' });
  }

  useEffect(() => {
    getData();
  }, []);

  if (reserva?.usuario.cod === Number(usuario?.cod) || (usuario?.tip === 'ADMINISTRADOR' || usuario?.tip === 'ASSISTENTE')) {
    return (
      <Container>
        {(usuario?.tip === 'ADMINISTRADOR' || usuario?.tip === 'ASSISTENTE') &&
          <h1>{reserva?.datres} - {reserva?.espaco.esp} - {reserva?.usuario.usu}</h1>
        }
        {(reserva && convidados) &&
          <CadastroGenerico
            {...getCadastroProps(
              'Convidado',
              '/convidados',
              campos,
              {
                cgc: cpfMask
              }
            )}
            reserva={reserva}
            convidados={convidados}
          />}
      </Container>
    );
  }

  return (
    <Container>
      <TableContainer>
        <h1>
          {reserva?.datres} - {reserva?.espaco.esp}
        </h1>
        <br />
        <TableWrapper>
          <Table>
            <thead>
              <Tr>
                <Th>Convidado</Th>
                {(import.meta.env.VITE_CODEMP !== '1') && <Th>CPF/CNPJ</Th>}
                <Th>Observação</Th>
                <Th>Verificado</Th>
              </Tr>
            </thead>
            <tbody>
              {convidados.map(convidado => (
                <Tr key={convidado.cod}>
                  <Td>{convidado.con}</Td>
                  {(import.meta.env.VITE_CODEMP !== '1') && <Td>{convidado?.cgc}</Td>}
                  <Td>{convidado?.obs}</Td>
                  <Td>
                    <input
                      type='checkbox'
                      checked={convidado?.che}
                      style={{ cursor: 'pointer', width: '15px', height: '15px' }}
                      onChange={() => verificarConvidado(convidado.cod, !convidado.che)}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </TableContainer>
    </Container>
  );

}
